 <template>
  <div class="layout-edit">
    <div>
      <div class="head_box">
        <span class="title">{{$t('layout.Layout Editing')}}  </span>
        <span style="flex:1; margin-left:10px"> {{width}}* {{height}}</span>
        <div>
          <span style="margin-right: 10px;">{{$t('layout.Item')}}</span>
          <el-select v-model="itemName" readonly @focus="selectCommodity"  placeholder="" size="small"></el-select>
          <el-button type="primary" size="small" @click="Preview" :disabled="itemId===''" style="margin-left: 20px;">{{$t('button.Preview')}}</el-button>
        </div>
      </div>
    </div>

    <!-- 布局块--开始 -->
    <div class="bottom_box">
      <div
        class="show-layout"
        :style="{
          width: width*scale+'px',
          height: height*scale+'px',
        }"
      >
        <div
          v-for="(item, index) in store.layoutLumpList"
          class="layout-item-container"
          :key="item.layoutId"
          :style="{
            top: item.startRowIndex*scale+'px',
            left: item.startCellIndex*scale+'px',
            height: item.lumpHeight *scale+'px',
            width: item.lumpWidth*scale+'px',
          }"
        >
          <layout-item :index="index" :scenarioId="scenarioId"></layout-item>
        </div>
      </div>
    </div>
     <!-- 布局块--结束 -->

    <div class="bottom_btn">
      <el-button size="small"  @click="Cancel">{{$t('button.Cancel')}}</el-button>
      <el-button type="success" size="small" @click="Save">{{$t('button.Save')}}</el-button>
    </div>

    <!-- 选择商品 -->
    <selectCommodity ref="selectCommodity" :scenarioId="scenarioId" @onSelectCommodity="handleSelectCommodity"></selectCommodity>

    <!-- 商品预览 -->
    <layoutPreview ref="layoutPreview"></layoutPreview>
  </div>
    
</template>
<script>
import selectCommodity from '@/components/select-commodity/index'
import layoutItem from './components/layoutItem'
import layoutPreview from '../layout/components/layout-preview.vue'
import { store } from "./store/index"

export default {
  name:'',
  components: {
    layoutItem,
    layoutPreview,
    selectCommodity
  },
  data() {
    return {
      store,
      scale:2.6,
      width: '',
      height: '',
      formData: {},
      options: [],
      layoutId: '',//布局id
      layoutStrategyId: '',//布局策略id
      layoutLumpEntityList: [],
      itemTags:[],
      scenarioId:'',
      itemId:'',
      itemName:''
    }
  },
  mounted(){
    this.getLayout()
  },
  methods: {
    // 获取布局信息
    async getLayout(){
      let { layoutId  } = this.$route.params
      let itemTags =[]
      console.log('获取布局',layoutId)
      // debugger
      if(layoutId){
        //修改布局
        this.layoutId = layoutId
        localStorage.setItem('layoutParams', JSON.stringify(this.$route.params))
      } else {
        // 刷新
        let cacheLayout = localStorage.getItem('layoutParams')
        let layoutParams = JSON.parse(cacheLayout)
        this.layoutStrategyId = layoutParams.layoutStrategyId
        this.layoutId = layoutParams.layoutId
      }

      const { data: { children, layoutWidth, layoutHeight, layoutStrategyId,scenarioId} } = await this.$Api.getLayout({ layoutId:this.layoutId })

      let len = children.length
      for(var i=0;i<len;i++){
        let index = i+1
        itemTags.push({
          label:this.$t('layout.Item')+index,
          value:index,
          disabled:false
        })
      }
      store.setItemTags(itemTags)
      store.setLayoutLumpList(children)

      this.layoutLumpEntityList = children
      this.width = layoutWidth
      this.height = layoutHeight
      this.layoutStrategyId = layoutStrategyId
      this.scenarioId = scenarioId
      this.calScale(layoutWidth,layoutHeight)

    },
    calScale(width){
      console.log('计算比例',width)
      let scale = 1
      switch (width) {
        case 200:
          // 200×200
          scale = 3
          break
        case 250:
          // 250×120
          scale = 2
          break
        case 296:
          // 296×152
          // 296×128
          scale = 2.5
          break
        case 384:
          // 384×184
          scale = 2
          break
        case 240:
          // 240×416
          scale = 1.5
          break
        case 400:
          // 400×300
          scale =2
          break
        case 648:
          // 648×480
          scale = 1
          break
        case 880:
          // 880×528
          scale =1
          break
        case 800:
          // 800×480
          scale =1
          break
        case 960:
          // 960×640
          scale = 0.8
          break
      }
      this.scale = scale
      // if(width <=296 ){
      //   this.scale = 1.8
      // } else if(width> 296 && width <=400 ){
      //   this.scale = 1.8
      // } else if(width >400 && width <= 900){
      //   this.scale = 1
      // } else if(width > 900){
      //   this.scale = 0.6
      // }
    },
    // 点击取消
    Cancel(){
      this.$router.push({
        name: 'layout'
      })
    },
    // 点击预览
    Preview(){
      console.log('预览时布局数据',store.layoutLumpList)
      let ltmList = []
      store.layoutLumpList.forEach((item) => {
        let temp={
          layoutLumpId:item.layoutLumpId,
          templateId:item.templateId,
          itemNumber:item.itemTag
        }
        ltmList.push(temp)
      })
      let obj={
        itemId:this.itemId,
        layoutId:this.layoutId,
        scenarioId:this.scenarioId,
        type:"item",
        ltmList
      }
      this.$refs.layoutPreview.open(obj)
      // this.$refs.preview.show()
    },
    // 选择商品
    selectCommodity(){
      this.$refs.selectCommodity.open()
    },
    // 选择商品确定
    handleSelectCommodity(arr){
      console.log('页面处理商品选择',arr)
      if(arr.length > 0){
        this.itemId= arr[0].itemId
        this.itemName = arr[0].itemName
      }
      
      
    },
    //处理绑定模板
    // handleLayoutItemBindTemplate(obj){
    //   console.log('布局编辑---绑定模板',obj)
    //   let layoutLumpEntityList = [...this.layoutLumpEntityList]
    //   layoutLumpEntityList.forEach(element => {
    //     if(element.layoutLumpId === obj.layoutLumpId){
    //       element.templateId = obj.templateId
    //     }
    //   });
    //   console.log('布局编辑---绑定模板layoutLumpEntityList',layoutLumpEntityList)
    //   this.layoutLumpEntityList = layoutLumpEntityList
    // },
    // 点击保存
    async Save(){
      console.log('this.layoutStrategyId', this.layoutStrategyId)
      console.log('store.layoutLumpList', store.layoutLumpList)
      let arr = store.layoutLumpList.map((item) => item.templateId)
      console.log('store.layoutLumpList----templateId', arr)
      
      const layoutLumpEntityList = store.layoutLumpList.map(it => {
        const { endCellIndex, endRowIndex, startCellIndex, startRowIndex, sort, templateId, itemTag, layoutLumpId } = it
        return { endCellIndex, endRowIndex, startCellIndex, startRowIndex, sort, templateId, itemTag, layoutLumpId }
      })
      let params ={
        layoutStrategyId: this.layoutStrategyId, 
        layoutId: this.layoutId, 
        layoutLumpEntityList
      }
      await this.$Api.updateLayout(params)
      this.$router.push({ //systemConfig/config
        name: 'layout'
      })
    },
  },
  beforeRouteLeave(to, from, next){
    // localStorage.removeItem('layoutParams')
    next()
  }
}
</script>
<style lang="scss" scoped>
  .layout-edit {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: scroll;
    .show-layout{
      border: 1px solid #C4C4C4;
      position: relative;
      .layout-item-container{
        position: absolute;
        border:1px solid #C4C4C4;
      }
    }
    .head_box {
      border-bottom: 1px solid #E6E5E5;
      .title {
        font-size: 20px;
      }
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .bottom_box {
      padding: 20px;
      margin-top: 20px;
      // overflow: scroll;
      // height: 60vh;
      border: 1px solid #E6E5E5;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bottom_btn {
      padding: 40px;
      text-align: center;
    }
    table {
      border-collapse: collapse;
      // border: 1px solid #E6E5E5;
      //background-color: red;
    }
    table td {
      //border: solid 1px #ccc;
      //padding: 2px;
      width: 2px;
      height: 2px;
      vertical-align: top;
      word-break: break-all;
    }
    .cannotselect {
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -khtml-user-select: none;
      user-select: none;
    }
    td.selected {
      background: #0094ff;
      color: #fff
    }

    ::v-deep .el-button{
      width: 110px;
    }

  }
  
</style>

