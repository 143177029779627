var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-edit"},[_c('div',[_c('div',{staticClass:"head_box"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('layout.Layout Editing'))+" ")]),_c('span',{staticStyle:{"flex":"1","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.width)+"* "+_vm._s(_vm.height))]),_c('div',[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('layout.Item')))]),_c('el-select',{attrs:{"readonly":"","placeholder":"","size":"small"},on:{"focus":_vm.selectCommodity},model:{value:(_vm.itemName),callback:function ($$v) {_vm.itemName=$$v},expression:"itemName"}}),_c('el-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary","size":"small","disabled":_vm.itemId===''},on:{"click":_vm.Preview}},[_vm._v(_vm._s(_vm.$t('button.Preview')))])],1)])]),_c('div',{staticClass:"bottom_box"},[_c('div',{staticClass:"show-layout",style:({
        width: _vm.width*_vm.scale+'px',
        height: _vm.height*_vm.scale+'px',
      })},_vm._l((_vm.store.layoutLumpList),function(item,index){return _c('div',{key:item.layoutId,staticClass:"layout-item-container",style:({
          top: item.startRowIndex*_vm.scale+'px',
          left: item.startCellIndex*_vm.scale+'px',
          height: item.lumpHeight *_vm.scale+'px',
          width: item.lumpWidth*_vm.scale+'px',
        })},[_c('layout-item',{attrs:{"index":index,"scenarioId":_vm.scenarioId}})],1)}),0)]),_c('div',{staticClass:"bottom_btn"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.Cancel}},[_vm._v(_vm._s(_vm.$t('button.Cancel')))]),_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":_vm.Save}},[_vm._v(_vm._s(_vm.$t('button.Save')))])],1),_c('selectCommodity',{ref:"selectCommodity",attrs:{"scenarioId":_vm.scenarioId},on:{"onSelectCommodity":_vm.handleSelectCommodity}}),_c('layoutPreview',{ref:"layoutPreview"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }