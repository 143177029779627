<template>
  <div class="layout-preview">
    <el-dialog
      v-model="dialogVisible"
      :title="$t('layout.Preview')"
      width="50%"
      :before-close="close"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
    >
      <div class="layout-preview-img">
        <template v-if="imgUrl">
          <img :src="imgUrl" class="img" />
        </template>
        <template v-else>
          <!-- <div>暂无图片</div> -->
        </template>
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import $Api from "@/libs/api/layout";
  export default {
    name:'layoutPreview',
    components: {},
    props: {
      layoutLumpId: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        dialogVisible:false,
        imgUrl:''
      }
    },
    methods: {
      open(row){
        this.dialogVisible = true
        this.getData(row)
      },
      close(){
        this.dialogVisible = false
        this.imgUrl =''
      },
      async getData(row){
        try {
          let params ={}
          let res = null
          if(row.type === "layoutblock"){
            params.layoutLumpId = row.layoutLumpId
            res = await $Api.previewLump(params)
          } else if (row.type === "layout"){
            params.layoutId = row.layoutId
            params.itemId = ''
            res = await $Api.previewLayout(params)
          } else if(row.type === 'item'){
            params.layoutId = row.layoutId
            params.itemId = row.itemId
            params.scenarioId = row.scenarioId
            params.ltmList = row.ltmList
            res = await $Api.previewLayoutBeforeSave(params)
          } else {
            params.layoutId = row.layoutId
            params.itemId = row.itemId
            params.scenarioId = row.scenarioId
            res = await $Api.previewLayout(params)
          }
          console.log('res=====', res)
          if(!res.state){
            this.$message.error(res.message)
            this.loading = false
            return
          } 
          this.imgUrl = res.data
        } catch (error) {
          this.$message.error(error.message)
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.layout-preview{
  ::v-deep .el-dialog {
    margin-top: 5vh!important;
  }
  .layout-preview-img{
    width: 400px;
    height: 400px;
    margin:0 auto;
    .img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>