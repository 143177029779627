// import router from "../axios";
import router, { $post } from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

export default {
  // 布局列表
  layoutGetList: (param) => {  
    return $post(`${baseUrl}layout/v1/getList`, param)  
  },
  //  获取价签类型--全部
  getAllTagType: (param) => {  
    return $post(`${baseUrl}tag/v1/getAllTagType`, param)  
  },
   //  获取价签类型---系统配置的
  getTagList: (param) => {  
    return $post(`${baseUrl}merchant/tag/type/v1/getList`, param )  
  },
  //创建布局组
  createGroup: (param) => {  
    return  $post(`${baseUrl}layout/v1/createGroup`, param)  
  },
  //更新布局组
  updateGroup: (param) => {  
      return $post(`${baseUrl}layout/v1/updateGroup`, param )  
  },
  // 校验布局组是否正在被使用
  checkGroupUsing: (param) => {  
    return $post(`${baseUrl}layout/v1/checkGroupUsing`, param)  
  },
  // 删除布局组
  physicalDeleteGroup: (param) => {  
    return $post(`${baseUrl}layout/v1/deleteGroup`, param)  
  },
  // 校验布局是否正在被使用
  checkLayoutUsing: (param) => {  
    return $post(`${baseUrl}layout/v1/checkLayoutUsing`, param)  
  },
  // 删除布局
  physicalDelete: (param) => {  
    return $post(`${baseUrl}layout/v1/physicalDelete`, param)  
  },
  // 复制布局策略 
  copyStrategy: (param) => {  
    return $post(`${baseUrl}layout/v1/copyStrategy`, param)  
  },
  // 获取模板类型
  templateTypeGetList: (param) => {  
    return $post(`${baseUrl}template/type/v1/getList`, param)  
  },
  //创建布局策略 
  createStrategy: (param) => {  
    return $post(`${baseUrl}layout/v1/createStrategy`, param)  
  },
  // 校验布局策略是否正在被使用
  checkStrategyUsing: (param) => {  
    return $post(`${baseUrl}layout/v1/checkStrategyUsing`, param )  
  },
  //批量删除布局策略信息
  batchPhysicalDeleteStrategy: (param) => {  
    return $post(`${baseUrl}layout/v1/batchDeleteStrategy`, param )  
  },
  // 通过布局块id绑定模板
  layoutLinkMore: (param) => {  
    return $post(`${baseUrl}layout/v1/linkMore`, param )  
  },
  // 获取布局块绑定的模板  
  getLayoutLinkMore: (param) => {  
    return $post(`${baseUrl}layout/v1/getLinkMore`, param )  
  },
  // item简化版列表查询
  getSimplifyList: (param) => {  
    return $post(`${baseUrl}item/v1/getSimplifyList`, param )  
  },
  // 布局列表中布局块预览
  previewLump: (param) => {  
    return $post(`${baseUrl}layout/v1/previewLump`, param )  
  },
  // 布局列表中布局块预览
  previewLayout: (param) => {  
    return $post(`${baseUrl}layout/v1/previewLayout`, param )  
  },
  previewLayoutBeforeSave: (param) => {  
    return $post(`${baseUrl}layout/v1/previewLayoutBeforeSave`, param )  
  },
  // 分享
  layoutShare:(param) => {
    return $post(`${baseUrl}layout/v1/share`, param )  
  }
}