<template>
  <div class="select-commodity-component">
    <el-dialog
      v-model="dialogVisible"
      :title="$t('layout.Items')"
      width="65vw"
      :before-close="close"
      :visible.sync="dialogVisible"
    >
    <div class="select-commodity">
      <el-table
        v-loading="loading"
        ref="tableRef"
        :data="tableData"
        style="width: 100%"
        height="50vh"
        min-height="50vh"
        highlight-current-row
        @sort-change="handleSort"
        @current-change="handleCheck"
        table-layout="fixed"
        border
      >
      <!--   Store Name  Item Name Item Code Barcode   -->
      <template v-if="multiple">
        <!-- 多选 -->
        <el-table-column type="selection" width="55">
          <el-table-column type="selection"  width="54">
            <template #header></template>
          </el-table-column>
        </el-table-column>
      </template>
      <template v-else>
        <!-- 单选 -->
        <el-table-column type="index" width="54">
          <el-table-column  width="54" align="center">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center;">
                <el-radio-group v-model="commodityInfo" size="small">
                  <el-radio :label="scope.row" size="large"><span style="display: none;"></span></el-radio>
                </el-radio-group>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </template>
        
        <el-table-column :label="$t('layout.Store Name')" header-align="center" min-width="140" prop="storeName">
          <el-table-column prop="storeName" min-width="140" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.storeName" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Item Name')" header-align="center" width="180" prop="itemName" sortable="custom">
          <el-table-column prop="itemName" min-width="140" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.itemName" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('layout.Item Code')" header-align="center" width="180" prop="itemCode" sortable="custom">
          <el-table-column prop="itemCode" min-width="140" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.itemCode" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />

      <div class="btn-section">
        <el-button @click="dialogVisible = false" size="small">{{$t('button.Cancel')}}</el-button>
        <el-button color="#28C76F" type="success" size="small" @click="handleSave">{{$t('button.Save')}}</el-button>
      </div>

    </div>
  </el-dialog>
  </div>
</template>
<script>
  import $Api from '@/libs/api/layout'
  import { debounce } from 'lodash';
  import pagination from '@/components/pagination'
  export default {
    name:'selectCommodity',
    components: {
      pagination
    },
    props: {
      scenarioId: {
        type: String,
        default: 'default',
      },
      multiple: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        dialogVisible:false,
        loading:true,
        tableData: [],
        searchParams:{
          bindState:'',
          itemName:'',
          itemCode:'',
          storeId:'',
          storeName:'',
          orders:[]
        },
        pageParams:{
          current:1,//当前页
          size:10,//单页显示最大数
          total:0,
          pageSizeArr:['10','20','50','100','200','500','1000'],
        },
        commodityInfo:{}
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
        this.getData()
      },
      // 关闭弹窗
      close(){
        this.dialogVisible = false
      },
      // 表格单选
      handleCheck(val){
        console.log('表格选中了...',val)
        if (val) this.commodityInfo = val
      },
      handleSelect(val){
        var len = val.length -1
        console.log('---handleSelect',val)

        this.$refs.tableRef.clearSelection();
        // let data = this.tableData[0]
        this.$refs.tableRef.toggleRowSelection(val[len]);
        // this.$refs.tableRef.toggleRowSelection(val);
        
      },
      // 获取数据
      getData:debounce (async function() {
        console.log('获取item列表-----')
        let {bindState,itemCode,itemName,storeName,orders} = this.searchParams
        let {current,size} = this.pageParams
        try{
          const params = {
            scenarioId:this.scenarioId,
            bindState,
            itemCode,
            itemName,
            storeId:localStorage.getItem('storeId'),
            storeName,
            current,
            size,
            orders
          }
          const res = await $Api.getSimplifyList(params)
          console.log('res=====', res)
          if (res.state === true) {
            this.tableData = res.data.records
            this.pageParams.total = res.data.total
            console.log('this.tableData=====', this.tableData)
          } else {
            this.$message.error(res.message)
          }
          this.loading = false
        } catch (err) {
          const { message} = err;
          this.$message.error(message)
          this.loading = false
        }
      },100),
      // 排序
      handleSort(column){
        console.log('排序',column)
        let arr = []
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr = []
        }
        this.searchParams.orders = arr;
        this.getData()
      },
      // 点击确定
      handleSave(){
        let selectedArr = []
        if(this.multiple){
          // 多选
          console.log('多选',this.$refs.tableRef.selection) 
          selectedArr = this.$refs.tableRef.selection
        } else {
          // 单选
          selectedArr.push(this.commodityInfo)
        }
        this.$emit('onSelectCommodity',selectedArr)
        this.dialogVisible = false
      },
    }
  }
</script>
<style lang="scss" scoped>
.select-commodity-component{
  .btn-section{
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    width: 100%;
    // height: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 0 4px rgb(0 0 0 / 30%);
  }
  // 重置样式
  .select-commodity ::v-deep {
    .el-table
      .el-table__header-wrapper
      .el-table__header
      .is-group
      tr:nth-of-type(2)
      th:nth-of-type(1)
      .cell {
      display: none;
    }
  }
  ::v-deep .el-table--border .el-table__cell{
    border-right:1px solid #EBEEF5 !important;
  }
  ::v-deep .el-dialog {
    margin-top: 5vh!important;
  }
  ::v-deep  .el-button{
    width: 120px;
  }
  ::v-deep  .el-radio{
    padding: 10px;
  }
  ::v-deep  .el-radio__label{
    padding: 0;
  }
  ::v-deep .el-table__row{
    cursor: pointer;
  }
}
</style>