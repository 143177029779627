import { reactive } from '@vue/composition-api'

export const store = reactive({
  itemTags:[],
  setItemTags(data){
    // console.log('----setItemTags',data)
    this.itemTags = data
  },
  layoutLumpList:[],
  setLayoutLumpList(data){
    this.layoutLumpList = data
  },
  
  
})