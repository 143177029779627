<template>
  <div class="layout-item">
    <div class="layout-item-bd">
      <div class="flag">
        <template>
          <!--  v-if="isEdit" -->
          <div class="arrow" ></div>
          <span class="show-index">{{store.layoutLumpList[index].sort}}</span>
        </template>
      </div>
      
      <div class="layout-item-operate">
        <!-- 绑定模板 -->
        <!-- <div :class="[`${store.layoutLumpList[index].templateName ? 'selected': 'false'}`,'bind-template']"  @click="openTemplate">
          <span> {{ `[${store.layoutLumpList[index].templateTypeName}]` store.layoutLumpList[index].templateName }}</span> 
          <span> Bind Template</span>
        </div> -->
        <div :class="[`${store.layoutLumpList[index].templateName ? 'selected': 'false'}`,'bind-template']"  
          @click="openTemplate" 
          :title="store.layoutLumpList[index].templateName ? `[${store.layoutLumpList[index].templateTypeName}] ${store.layoutLumpList[index].templateName}`  : $t('layout.Bind Template') "
          > 
          <span v-if="store.layoutLumpList[index].templateName">
            {{store.layoutLumpList[index].templateTypeName ? '['+ store.layoutLumpList[index].templateTypeName + ']' + store.layoutLumpList[index].templateName :  store.layoutLumpList[index].templateName}}
          </span>
          <span v-else>{{$t('layout.Link Template')}}</span>
          <!-- {{store.layoutLumpList[index].templateName ? `[${store.layoutLumpList[index].templateTypeName}] ${store.layoutLumpList[index].templateName}`  : $t('layout.Bind Template') }} -->
        </div>

        <!-- 选择编号 -->
        <el-select 
          v-model="store.layoutLumpList[index].itemTag"  
          :placeholder="$t('layout.Select')" 
          size="small"  
          @change="handelItemTagChange" 
          @focus="handelItemTagChange"
          clearable
          :disabled="!store.layoutLumpList[index].templateName"
        >
          <el-option
            v-for="item in store.itemTags"
            :key="item.label"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
            >
          </el-option>
        </el-select>

        <!-- 展示尺寸 -->
        <span class="text"> {{store.layoutLumpList[index].lumpWidth}} * {{store.layoutLumpList[index].lumpHeight}}</span>
      </div>
    </div>
    <!-- 绑定模板的图片 -->
    <template v-if="store.layoutLumpList[index].templateImageUrl">
      <div class="img-container">
        <img class="img" :src="store.layoutLumpList[index].templateImageUrl"/>
      </div>
      <!-- <img :src="store.layoutLumpList[index].templateImageUrl"/> -->
    </template>
    
    <selectTemplate 
      ref="selectTemplate" 
      :showRadio="true"  
      :width="store.layoutLumpList[index].lumpWidth" 
      :height="store.layoutLumpList[index].lumpHeight"
      :scenarioId = "scenarioId"
      @onTemplateConfirm="handleTemplateConfirm"
    ></selectTemplate>
  </div>
</template>
<script>
import selectTemplate from '@/components/select-template/index'
import { store } from "../store/index"
export default {
  name:'layout-item',
  components: {
    selectTemplate
  },
  props: {
    index:{
      type: Number
    },
    scenarioId:{
      type:String,
      value:''
    }
  },
  data() {
    return {
      store,
      // editData: this.currentLayout,
      formData: {
        storeName:''
      },
      // editData:{
      //   templateId:'',
      //   templateName:'',
      //   itemTag:''
      // },
      selected: null,
      itemTag:''
    }
  },
  created(){
    
  },
  mounted(){
    
  },
  methods: {
    async show(){
      this.$refs['layout-modal'].show()
    },
    // 点击--绑定模板
    openTemplate(){
      console.log('点击--绑定模板')
      // this.$refs.templateList.show()
      this.$refs.selectTemplate.show()

    },
    // 绑定模板成功
    handleTemplateConfirm(obj){
      console.log('绑定模板成功',obj)
      console.log('this.index',this.index)
      if(!(obj && obj.templateId)) return
      let layoutLumpList = [...store.layoutLumpList]
      layoutLumpList[this.index].templateTypeName = obj.templateTypeName
      layoutLumpList[this.index].templateName = obj.templateName
      layoutLumpList[this.index].templateId = obj.templateId
      layoutLumpList[this.index].templateImageUrl = obj.templateImageUrl
      store.setLayoutLumpList(layoutLumpList)
    },
    //item tag --Change
    handelItemTagChange(val){
      console.log('item tag--Change',val)
      console.log('item tag--itemTag',store.layoutLumpList[this.index].itemTag)
      let itemTags =[...store.itemTags] 
      let  layoutLumpList = store.layoutLumpList
      // itemTag
      itemTags.forEach( element => {
        element.disabled = false
        var arr = layoutLumpList.filter(item => item.itemTag === element.value)
        console.log({arr})
        if(arr.length >0){
          element.disabled = true
        }
      });
      console.log('设置itemTags----',itemTags)
      store.setItemTags(itemTags)
    },
    async getAllDescendantStores(){
      const res = await this.$Api.getAllDescendantStores()
      const { data: { data }} = res
      return data ? [ data ] : []
    },
  }
}
</script>
<style lang="scss" scoped>
  .layout-item{
    width: 100%;
    height: 100%;
    // border: 1px solid #C4C4C4;
    position: relative;
    .layout-item-bd{
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      z-index: 2;
      .flag{
        width: 20px;
        height: 20px;
        position: relative;
        .arrow{
          width: 0;
          height: 0;
          border: 14px solid #EAEFFF;
          color: #3461FF;
          border-bottom-color: transparent;
          border-right-color: transparent;
        }
        .show-index{
          position: absolute;
          top: 2px;
          left: 4px;
          color: #3461FF;
          font-size: 10px;
        }
      }
      .layout-item-operate{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        .bind-template{
          width: 100px;
          height: 32px;
          line-height: 32px;
          color: #3461FF;
          font-weight: 400;
          text-align: center;
          background: #EDF1FF;
          border: 1px solid #A5B6F4;
          opacity: 0.46;
          border-radius: 4px 0px 0px 4px;
          font-size: 12px;
          cursor: pointer;
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
          &.selected{
            background: #35B871;
            border: 1px solid #35B871;
            color: #fff;
            opacity:1
          }
        }
        .text {
          width: 100px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #F4F4F4;
          border: 1px solid #C2C2C2;
          border-radius: 4px 0px 0px 4px;
        }
        .el-select {
          width: 100px;
        }
      }
    }
    .img-container{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
</style>